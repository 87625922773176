
import { defineComponent } from 'vue';
import FormField from '@/components/forms/FormField.vue';
import mask from '@/utils/mask';

export default defineComponent({
  name: 'business-details-form',
  emits: [
    'input',
    'businessColorChange',
    'businessColorActiveChange',
    'businessTextColorChange',
    'businessTextColorActiveChange',
    'businessLogoChange',
  ],
  props: {
    values: {
      type: Object,
      default: () => {
        undefined;
      },
    },
  },
  components: {
    FormField,
  },
  computed: {
    abnMasking() {
      return mask.business.abn;
    },
    acnMasking() {
      return mask.business.acn;
    },
    phoneMasking() {
      return mask.phone.aus;
    },
    mobileMasking() {
      return mask.mobile.aus;
    },
  },
  methods: {
    // eslint-disable-next
    handleBusinessLogoChange(value) {
      this.$emit('businessLogoChange', value);
    },
    handleBusinessColorPickerChange(value) {
      this.$emit('businessColorChange', value);
    },
    handleBusinessColorPickerActiveChange(value) {
      this.$emit('businessColorActiveChange', value);
    },
    handleBusinessTextColorPickerChange(value) {
      this.$emit('businessTextColorChange', value);
    },
    handleBusinessTextColorPickerActiveChange(value) {
      this.$emit('businessTextColorActiveChange', value);
    },
  },
});
