const imageMIMES = {
  jpg: 'image/jpeg',
  png: 'image/png',
  gif: 'image/gif',
};

export const toDataUrl = (image) => {
  const reader = new FileReader();
  let imgSrc = '' as any;
  reader.readAsDataURL(image);
  reader.onload = () => (imgSrc = reader.result);

  return imgSrc;
};

export const rawImageToDataUrl = (rawImage, mime = 'jpeg') => {
  const bytes = new Uint8Array(rawImage);
  const binary = bytes.reduce(
    (data, b) => (data += String.fromCharCode(b)),
    ''
  );
  return `data:${mime};base64,` + btoa(binary);
};

export const imageMIME = (extension) => {
  return imageMIMES[extension];
};

export default {
  toDataUrl,
  rawImageToDataUrl,
  imageMIME,
};
