enum Actions {
  ADD_BUSINESS = 'addBusiness',
  UPDATE_BUSINESS = 'updateBusiness',
  FETCH_BUSINESS_LOGO = 'fetchBusinessLogo',
  FETCH_AGENT_BUSINESS_LOGO = 'fetchAgentBusinessLogo',
  ABN_LOOKUP = 'abnLookup',
  SAVE_ABN_LOOKUP = 'saveAbnLookup',
  APPROVE_BUSINESS = 'approveBusiness',
  SUSPEND_BUSINESS = 'suspendBusiness',
  DELETE_BUSINESS = 'deleteBusiness',
}

enum Mutations {
  SET_ACTION_ERROR = 'setBusinessActionError',
  SET_BUSINESS_LOGO = 'setBusinessLogo',
  SET_AGENT_BUSINESS_LOGO = 'setAgentBusinessLogo',
}

enum Getters {
  GET_BUSINESS_LOGO = 'getBusinessLogo',
  GET_ACTION_ERROR = 'getBusinessActionError',
  GET_AGENT_BUSINESS_LOGO = 'getAgentBusinessLogo',
}

export { Actions, Mutations, Getters };
