
import { defineComponent } from 'vue';
import BusinesProfileForm from '@/views/new-design/pages/Profile/BusinessDetailsForm.vue';
import { Getters as AuthGetters } from '@/store/enums/AuthEnums';
import { mapGetters, mapActions } from 'vuex';
import {
  Actions as BusinessActions,
  Getters as BusinessGetters,
  Mutations as BusinessMutations,
} from '@/store/enums/BusinessEnums';
import { Actions as SystemActions } from '@/store/enums/SystemEnums';
import toasts from '@/utils/toasts';
import parser from '@/utils/parser';
import { Business } from '@/models/AgencyModel';
import BusinessLogo from '@/components/logo/BusinessLogo.vue';

import image from '@/utils/image';
import text from '@/utils/text';
import store from '@/store';

export default defineComponent({
  name: 'profile-overview',
  components: {
    BusinesProfileForm,
    BusinessLogo,
  },
  data: () => ({
    isEdit: false,
    updateMode: false,
    formSubmitLoading: false,
    logoData: '',
  }),
  mounted() {
    this.loadInit();

    this.displayLogo();
  },
  computed: {
    ...mapGetters({
      authUser: AuthGetters.GET_AUTH_USER,
      getBusinessLogo: BusinessGetters.GET_BUSINESS_LOGO,
      errors: BusinessGetters.GET_ACTION_ERROR,
    }),
    businessCountryName() {
      if (!this.authUser?.business_country) return;

      return parser.country(this.authUser?.business_country);
    },

    businessStateName() {
      if (!this.authUser?.business_state) return;

      return parser.state(this.authUser?.business_state);
    },
  },
  methods: {
    ...mapActions({
      updateBusiness: BusinessActions.UPDATE_BUSINESS,
      addBusiness: BusinessActions.ADD_BUSINESS,
      getAllCountries: SystemActions.LIST_ALL_COUNTRIES,
      getAllStates: SystemActions.LIST_STATES,
      fetchBusinessLogo: BusinessActions.FETCH_BUSINESS_LOGO,
    }),
    loadInit() {
      this.getAllCountries();
      if (typeof this.authUser?.business_country !== 'undefined') {
        this.getAllStates(this.authUser?.business_country);
      }
    },
    async displayLogo() {
      if (!this.authUser.business_logo) return;

      const mime = image.imageMIME(
        text.fileExtension(this.authUser.business_logo)
      );

      return await this.fetchBusinessLogo().then(async (logoRawImageData) => {
        this.logoData = image.rawImageToDataUrl(logoRawImageData, mime);
        store.commit(BusinessMutations.SET_BUSINESS_LOGO, this.logoData);
      });
    },

    handleEdit(value) {
      this.isEdit = value;
    },

    handleSaveBusinessProfile(formData) {
      if (this.formSubmitLoading) return;
      this.formSubmitLoading = true;

      delete formData.id;

      return this.addBusiness(formData)
        .then(async () => {
          this.isEdit = false;
          this.formSubmitLoading = false;

          if (formData.business_logo !== null) {
            await this.displayLogo();
          }

          toasts.success('Business Profile Successfully Saved');
        })
        .catch(() => {
          this.formSubmitLoading = false;
          const { errors, message } = this.errors;
          toasts.error(errors, message);
        });
    },
    handleUpdateBusinessProfile(formData) {
      if (this.formSubmitLoading) return;
      this.formSubmitLoading = true;

      delete formData.id;

      return this.updateBusiness(formData)
        .then(() => {
          this.isEdit = false;
          this.formSubmitLoading = false;

          toasts.success('Business Profile Successfully Updated', async () => {
            if (formData.business_logo !== null) {
              await this.displayLogo();
            }
          });
        })
        .catch(() => {
          this.formSubmitLoading = false;
          const { errors, message } = this.errors;
          toasts.error(errors, message);
        });
    },
    toggleEdit() {
      this.isEdit = !this.isEdit;

      this.updateMode = false;
      if (typeof this.authUser.business_id !== 'undefined') {
        this.updateMode = true;
      }
    },
  },
});
