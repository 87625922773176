import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-row flex-wrap justify-content-start gap-5 mb-5" }
const _hoisted_2 = { class: "w-40 d-flex flex-row gap-3" }
const _hoisted_3 = { class: "w-50" }
const _hoisted_4 = { class: "w-50" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormField = _resolveComponent("FormField")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_FormField, {
      required: true,
      name: "business_name",
      containerClass: "w-50",
      label: "Business Name",
      placeholder: "Business Name",
      type: "text"
    }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_FormField, {
          value: _ctx.values.business_color,
          required: true,
          colorFormat: "hex",
          name: "business_color",
          containerClass: "",
          type: "colorpicker",
          label: "Background Color",
          onColorPickerChange: _ctx.handleBusinessColorPickerChange,
          onColorPickerActiveChange: _ctx.handleBusinessColorPickerActiveChange
        }, null, 8, ["value", "onColorPickerChange", "onColorPickerActiveChange"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_FormField, {
          value: _ctx.values.business_text_color,
          required: true,
          colorFormat: "hex",
          name: "business_text_color",
          label: "Text Color",
          containerClass: "",
          type: "colorpicker",
          onColorPickerChange: _ctx.handleBusinessTextColorPickerChange,
          onColorPickerActiveChange: _ctx.handleBusinessTextColorPickerActiveChange
        }, null, 8, ["value", "onColorPickerChange", "onColorPickerActiveChange"])
      ])
    ]),
    _createVNode(_component_FormField, {
      required: true,
      name: "business_email_address",
      containerClass: "w-45",
      label: "Email Address",
      placeholder: "Email Address",
      type: "email"
    }),
    _createVNode(_component_FormField, {
      required: true,
      name: "business_website",
      containerClass: "w-45",
      label: "Website",
      placeholder: "example.com",
      type: "text"
    }),
    _createVNode(_component_FormField, {
      required: true,
      name: "business_logo",
      containerClass: "w-45",
      label: "Business Logo",
      type: "file",
      onFileUpload: _ctx.handleBusinessLogoChange,
      accept: ".png, .jpg, .jpeg"
    }, null, 8, ["onFileUpload"]),
    _createVNode(_component_FormField, {
      required: true,
      name: "abn",
      containerClass: "w-45",
      label: "ABN",
      placeholder: "ABN",
      type: "text",
      mask: _ctx.abnMasking
    }, null, 8, ["mask"]),
    _createVNode(_component_FormField, {
      required: true,
      name: "acn",
      containerClass: "w-45",
      label: "ACN",
      placeholder: "ACN",
      type: "text",
      mask: _ctx.acnMasking
    }, null, 8, ["mask"]),
    _createVNode(_component_FormField, {
      required: true,
      name: "business_phone_number",
      containerClass: "w-45",
      label: "Phone Number",
      placeholder: "Phone Number",
      type: "text",
      mask: _ctx.phoneMasking
    }, null, 8, ["mask"]),
    _createVNode(_component_FormField, {
      name: "business_mobile_number",
      containerClass: "w-45",
      label: "Mobile Number",
      placeholder: "Mobile Number",
      type: "text",
      mask: _ctx.mobileMasking
    }, null, 8, ["mask"])
  ]))
}